/* OurServices.css */

.slick-arrow {
    background: #000 !important;
    color: #fff !important;
    border-radius: 50% !important;
    position: relative !important;
    bottom: -20px !important;
    cursor: pointer !important;
    margin: 0 -10px; /* Adjust the margin to decrease the distance */
}

.slick-arrows-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 0px; /* Add gap property to control the distance between arrows */
}

.service-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 350px;
    height: 100%;
}

.service-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.service-icon {
    margin-bottom: 50px;
}

/* Ensure you import Swiper styles first */
@import 'swiper/swiper-bundle.css';

@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.card {
  opacity: 0; /* Start with hidden cards */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Smooth animation */
}

.card.visible {
  animation: slideUp 0.5s forwards; /* Apply slideUp animation */
}

.card.visible:nth-child(1) {
  animation-delay: 0.1s;
}

.card.visible:nth-child(2) {
  animation-delay: 0.3s;
}

.card.visible:nth-child(3) {
  animation-delay: 0.4s;
}

.card.visible:nth-child(4) {
  animation-delay: 0.5s;
}

.card.visible:nth-child(5) {
  animation-delay: 0.6s;
}

.card.visible:nth-child(6) {
  animation-delay: 0.7s;
}

.card:hover {
  transform: scale(1.05); /* Enlarge the card */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

/* Custom styles to remove horizontal scrollbar from Swiper */
.swiper-container {
  overflow: hidden !important; /* Hide the scrollbar */
}

.swiper-wrapper {
  overflow: visible !important; /* Make sure swiper slides are visible */
}

.swiper-slide {
  overflow: hidden !important; /* Ensure each slide hides overflow */
}

/* Ensure scrollbar elements are hidden */
.swiper-scrollbar {
  display: none !important;
}

/* Hide default swiper pagination bullets */
.swiper-pagination-bullets {
  display: none !important;
}

/* Custom navigation arrow styles */
.swiper-button-next,
.swiper-button-prev {
  width: 16px !important; /* Smaller arrow size */
  height: 16px !important; /* Smaller arrow size */
  color: var(--swiper-navigation-color) !important;
}

.dark .swiper-button-next,
.dark .swiper-button-prev {
  color: white !important;
}

.light .swiper-button-next,
.light .swiper-button-prev {
  color: black !important;
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev {
  width: 16px !important;
  height: 16px !important;
}

/* Custom swiper for mobile view */
.custom-swiper .swiper-slide {
  display: flex;
  justify-content: center;
}

/* ============================ */
/* WhyChooseDetailPage.css */

/* General Table Styles */
table {
  border-collapse: collapse;
  width: 100%;
}

/* Column Styles */
.feature-column {
  background-color: #009aa1; /* Teal */
  color: white;
  padding: 15px 10px;
}

.vassistpro-column {
  background-color: #29323c; /* Dark Gray */
  color: white;
  padding: 10px 15px;
  position: relative;
}

.agencies-column {
  background-color: #f6f5f3; /* Light Gray */
  color: #29323c; /* Dark Text */
  padding: 15px 25px;
}

.inhouse-column {
  background-color: #f6f5f3; /* Light Gray */
  color: #29323c; /* Dark Text */
  padding: 20px 15px;
}

/* Header Cells */
.feature-header {
  background-color: #009aa1;
  color: white;
  font-weight: bold;
  font-size: 1.125rem; /* text-lg */
  padding: 20px 15px;
  border-radius: 20px 0 0 0;
}

.vassistpro-header {
  background-color: #29323c;
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 15px 10px;
  position: relative;
}

.agencies-header {
  background-color: #f6f5f3;
  color: #29323c;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 10px 20px;
}

.inhouse-header {
  background-color: #f6f5f3;
  color: #29323c;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 30px 15px;
  border-radius: 0 20px 0 0;
}

/* Data Cells */
.feature-cell {
  background-color: #009aa1;
  color: white;
  font-weight: 600;
  font-size: 1rem; /* text-base */
  padding: 15px 10px;
}

.vassistpro-cell {
  background-color: #29323c;
  color: white;
  padding: 10px 15px;
  position: relative;
}

.agencies-cell {
  background-color: #f6f5f3;
  color: #29323c;
  padding: 15px 25px;
}

.inhouse-cell {
  background-color: #f6f5f3;
  color: #29323c;
  padding: 20px 15px;
}

/* Rounded Corners */
.feature-cell.rounded-bl-2xl {
  border-radius: 0 0 0 20px;
}

.inhouse-cell.rounded-br-2xl {
  border-radius: 0 0 20px 0;
}

/* Icons */
.header-icon {
  position: absolute;
  top: -50px;
  left: -20px;
  width: 100px;
  height: 100px;
  z-index: 10;
}

.bottom-icon {
  position: absolute;
  bottom: -50px;
  right: -20px;
  width: 100px;
  height: 100px;
  z-index: 10;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-icon,
  .bottom-icon {
    display: none;
  }
}

/* Dark Mode Adjustments */
@media (prefers-color-scheme: dark) {
  .agencies-column,
  .inhouse-column,
  .agencies-header,
  .inhouse-header,
  .agencies-cell,
  .inhouse-cell {
    background-color: #29323c;
    color: white;
  }
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 28px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white; /* Default background color for dark mode */
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 5px;
  bottom: 3px;
  background-image: url('../../assets//ForHeaderSection/moon.svg'); /* Default icon for dark mode */
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black; /* Background color for light mode */
}

input:checked + .slider:before {
  transform: translateX(18px); /* Move slider to the right */
  background-image: url('../../assets//ForHeaderSection/sun.svg'); /* Icon for light mode */
  background-color: transparent; /* Remove background color */
}

.containerForMobile {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.sectionForMobile {
    padding: 60px 0;
    background-color: #ffffff; /* Light mode background */
}

.sectionForMobile.dark {
    background-color: #000000; /* Dark mode background */
}

.section-titleForMobile {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #1a202c; /* Light mode text color */
}

.section-titleForMobile.dark {
    color: #e2e8f0; /* Dark mode text color */
}

.section-subtitleForMobile {
    margin-top: 10px;
    font-size: 1.125rem;
    text-align: center;
    color: #4a5568; /* Light mode text color */
}

.section-subtitleForMobile.dark {
    color: #a0aec0; /* Dark mode text color */
}

.review-gridForMobile,
.review-grid-single-columnForMobile {
    display: grid;
    row-gap: 40px; /* Equal vertical spacing between rows */
    column-gap: 40px; /* Equal horizontal spacing between columns */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    overflow: hidden;
    position: relative;
    margin-top: 40px;
}

.review-grid-single-columnForMobile {
    grid-template-columns: 1fr;
}

.review-columnForMobile,
.review-grid-single-columnForMobile {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Ensure equal spacing between review cards in each column */
    position: relative;
}

.review-columnForMobile::before,
.review-columnForMobile::after,
.review-grid-single-columnForMobile::before,
.review-grid-single-columnForMobile::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 20%;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    z-index: 1;
}

.review-columnForMobile.dark::before,
.review-columnForMobile.dark::after,
.review-grid-single-columnForMobile.dark::before,
.review-grid-single-columnForMobile.dark::after {
    background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0)); /* Dark mode gradient */
}

.review-columnForMobile::before,
.review-grid-single-columnForMobile::before {
    top: 0;
}

.review-columnForMobile::after,
.review-grid-single-columnForMobile::after {
    bottom: 0;
    transform: rotate(180deg);
}

.reviewForMobile {
    background: #fff; /* Light mode background */
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: scrollUpFastForMobile 7s linear infinite;
}

.reviewForMobile.dark {
    background: #151515;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.star-ratingForMobile {
    display: flex;
}

.starForMobile {
    height: 20px;
    width: 20px;
    fill: #e2e8f0;
}

.star-filledForMobile {
    fill: #0ea5e9;
}

.review-titleForMobile {
    margin-top: 10px;
    font-size: 1.125rem;
    font-weight: 600;
    color: #1a202c; /* Light mode text color */
}

.review-titleForMobile.dark {
    color: #e2e8f0; /* Dark mode text color */
}

.review-bodyForMobile {
    margin-top: 10px;
    font-size: 1rem;
    color: #4a5568; /* Light mode text color */
}

.review-bodyForMobile.dark {
    color: #a0aec0; /* Dark mode text color */
}

.review-authorForMobile {
    margin-top: 10px;
    font-size: 0.875rem;
    color: #718096; /* Light mode text color */
}

.review-authorForMobile.dark {
    color: #cbd5e0; /* Dark mode text color */
}

@keyframes scrollUpFastForMobile {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 1;
    }
}

@keyframes scrollUpForMobile {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 1;
    }
}
